import React from "react"
import { Link } from "gatsby"
import { Button, Container, Col, Row, Image } from "react-bootstrap"
import Layout from "../components/layout"
import SEO from "../components/seo"

import bebe2 from "../images/bebe/bebe2.png"
import bebe3 from "../images/bebe/bebe3.png"

import joy2 from "../images/joy/joy2.png"
import joy3 from "../images/joy/joy3.jpg"

const AboutPage = () => (
  <Layout pageInfo={{ pageName: "about" }}>
    <SEO title="About" />
    <Container className="main-container">
      <Container className="team-container">
        <Row className="about-header">
          <Col>Why Green Velvet?</Col>
        </Row>
        <Row>
          <Col className="content">
            Have you ever walked into a forest completely blanketed in moss or
            green velvet? We wanted an organic feel, something that is found in
            the forests, mountains, beaches, even in the cities. Green Velvet
            can be found anywhere and we want to be your local source wherever
            you travel, for now we will just stick with what we know best:
            Telluride, Colorado.
          </Col>
        </Row>
        <Row className="about-header">
          <Col>We all deserve a little Green Velvet luxury!</Col>
        </Row>
      </Container>
      <Row>
        <Col md={6}>
          <Container className="team-container">
            <Row className="team-header">
              <Col>
                <span className="team-name">Bebe Hinde</span>
                <br />
                Co-Owner/Memory Creator
                <br />
                <Image
                  roundedCircle
                  className="team-photo"
                  src={bebe3}
                  alt="Bebe"
                  height="125"
                />
                <hr />
              </Col>
            </Row>

            <Row style={{ height: "auto" }}>
              <Col className="content">
                Bebe grew up in Telluride and has worked many different
                positions in the hospitality industry, most notably, being the
                Director of Guest Relations for the River Club for nine years.
                Bebe is also a local real estate broker with Telluride Real
                Estate Corp/Christie's International and is well versed in the
                regions real estate market. She knows Telluride in and out and
                loves to be able to share that passion with any visitor or part
                time resident. Bebe married long time local and 'ski bum',
                Dustin Hinde, and together they are raising the next generation
                of Telluride mountain kids, Thatcher Couloir and Rye Wilder.
                <br />
                <hr />
                <Image
                  roundedCircle
                  className="team-photo"
                  src={bebe2}
                  alt="Bebe"
                  height="200"
                />
              </Col>
            </Row>
          </Container>
        </Col>
        <Col md={6}>
          <Container className="team-container">
            <Row className="team-header">
              <Col>
                <span className="team-name">Joy Littleton</span>
                <br />
                Co-Owner/Memory Curator
                <br />
                <Image
                  roundedCircle
                  className="team-photo"
                  src={joy3}
                  alt="Joy"
                  height="125"
                />
                <hr />
              </Col>
            </Row>

            <Row style={{ height: "auto" }}>
              <Col className="content">
                Joy is a girl from Ohio who fell in love with the mountains
                because of Telluride. She first worked in hospitality as a
                Segway tour guide in Cincinnati where she learned what a
                memorable impact she could have on her guests’ experience. Joy
                took this passion to Telluride as a concierge, where she planned
                events and trips for three years. Joy enjoys all the best that
                Telluride has to offer, especially the food, hiking,
                snowshoeing, and 4x4 exploring. Joy lives in Telluride with her
                partner, Jordan, and their puggle, Cricket.
                <br />
                <br />
                <hr />
                <Image
                  roundedCircle
                  className="team-photo"
                  src={joy2}
                  alt="Joy"
                  height="200"
                />
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
      <Container className="main-container">
        <Row>
          <Col>
            <Link to="/contact" style={{ textDecoration: "none" }}>
              <Button
                className="btn btn-block to-contact"
                type="submit"
                variant="success"
              >
                Plan your trip with us!
              </Button>
            </Link>
          </Col>
        </Row>
      </Container>
    </Container>
  </Layout>
)

export default AboutPage
